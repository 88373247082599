import React, { Component } from 'react'

import tierList, {tierCollection} from './constants/tierList'
import {EMPTY_KOEF_DEFAULT} from './constants/EMPTY_KOEF'

import trackAnalyticEvent from '@/utils/trackAnalyticEvent'

import LootGeneratorComponent from './LootGeneratorComponent'

class LootGeneratorContainer extends Component {
  state = {
    tier: tierList[0].id,
    emptyKoef: EMPTY_KOEF_DEFAULT,
  }

  onChangeTier = tier => {
    this.setState({tier})
  }

  onChangeEmptyKoef = emptyKoef => {
    this.setState({
      emptyKoef: Number(emptyKoef),
    })
  }

  generateHook = () => {
    trackAnalyticEvent('generate-loot')
  }

  render () {
    const {tier, emptyKoef} = this.state
    const {list} = tierCollection[tier]

    return (
      <LootGeneratorComponent
        tier={tier}
        emptyKoef={emptyKoef}
        list={list}
        onChangeEmptyKoef={this.onChangeEmptyKoef}
        onChangeTier={this.onChangeTier}
        generateHook={this.generateHook}
      />
    )
  }
}

export default LootGeneratorContainer
