import listToCollectionById from '@/utils/listToCollectionById'

import lootRawList from '@/constants/lootRawList'

const lootList = lootRawList.map(
  (loot, i) => ({
    ...loot,
    id: `loot_${i + 1}`,
  }),
)

export default lootList
export const lootCollection = listToCollectionById(lootList)
