import React from 'react'

import upLetter from '@/utils/upLetter'

import Price from '@/components/Price'
import Icon from '@/components/Icon'

const LootItem = ({ name, description, title, cost, icon }) => (
  <span
    title={title}
    className='LootItem'
  >
    {
      icon
        ? <Icon icon={icon}/>
        : null
    }
    <b>{upLetter(name)}</b>{description ? ', ' : ' '}
    {`${description} `}
    {
      cost
        ? <>(<Price price={cost}/>)</>
        : null
    }
  </span>
)

export default LootItem
