import React from 'react'

import Layout from '@/components/Layout'
import Seo from '@/components/Seo'
import LootGenerator from '@/components/LootGenerator'

export default () => (
  <Layout>
    <Seo
      title='Генератор лута для D&D'
    />
    <LootGenerator/>
  </Layout>
)
