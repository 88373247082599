import {
  LOOT_TYPE_ART, LOOT_TYPE_CLOTH,
  LOOT_TYPE_FOOD,
  LOOT_TYPE_GEM,
  LOOT_TYPE_TOOL,
  LOOT_TYPE_TRINKET,
  LOOT_TYPE_WEAPON,
} from '@/constants/lootTypeList'
import {
  SOURCE_DMG,
  SOURCE_PHB,
} from '@/constants/sourceList'

export default [
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `азурит`,
    description: `непрозрачный, пёстрый тёмно-синий`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `бирюза`,
    description: `непрозрачная, зелёно-голубая`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `гематит`,
    description: `непрозрачный, серо-чёрный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 2500,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: true,
    name: `серебряный кувшин`,
    description: ``,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 2500,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `резная статуэтка из кости`,
    description: ``,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 2500,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `вышитый шёлковый носовой платок`,
    description: ``,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 2500,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `маленький золотой браслет`,
    description: ``,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 2500,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `одеяние`,
    description: `из золотой парчи`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 2500,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `чёрная бархатная маска`,
    description: `вышитая серебряной нитью`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 2500,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `медная чаша`,
    description: `с серебряной филигранью`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 2500,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `пара гравированных игральных костей`,
    description: ``,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 2500,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `небольшое зеркальце`,
    description: `в расписной деревянной раме`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 2500,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `золотой медальон`,
    description: `с портретом возлюбленной внутри`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `мумифицированная рука гоблина`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `старая стеклянная шахматная фигура`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `золотая монета`,
    description: `отчеканенная в неизвестной стране`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кусочек кристалла`,
    description: `слабо светящийся в лунном свете`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `дневник`,
    description: `написанный на неизвестном вам языке`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `латунное кольцо`,
    description: `которое не темнеет со временем`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `пара игральных костей`,
    description: `у обеих вместо шестёрок нарисованы черепа`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `небольшой идол`,
    description: `изображающий страшное чудовище, насылающий кошмары, когда Вы спите рядом с ним`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `верёвочное ожерелье`,
    description: `на котором висят четыре мумифицированных эльфийских пальца`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `квитанция для получения посылки`,
    description: `в неизвестном вам королевстве`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `30-граммовый кусочек неизвестного материала`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `маленькая тряпичная кукла`,
    description: `утыканная иголками`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `зуб неизвестного зверя`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `огромная чешуйка`,
    description: `возможно, драконья`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `ярко-зелёное перо`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `старая гадальная карта`,
    description: `с лицом, похожим на ваше`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 5,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `стеклянная сфера`,
    description: `заполненная дымом`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `полукилограммовое яйцо с ярко-красной скорлупой`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 50,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `курительная трубка`,
    description: `из которой вылетают пузыри`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: true,
    name: `стеклянный графин с мутной жидкостью`,
    description: `в которой плавает странный кусочек мяса`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `крошечная музыкальная шкатулка гномьей работы`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `маленькая деревянная статуэтка нарядного полурослика`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `латунная сфера`,
    description: `покрытая странными рунами`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `разноцветный каменный диск`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 50,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `крошечный серебряный ворон`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `сумка`,
    description: `в которой лежат 47 человеческих зубов. Один с кариесом.`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кусок обсидиана`,
    description: `всегда тёплый на ощупь`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `коготь дракона`,
    description: `подвешенный на простом кожаном шнурке`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `пара старых носков`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `чистая книга`,
    description: `на страницах которой не получается писать ни чернилами, ни мелом, ни углём, и никакими другими обычным средствами`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 50,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `серебряный значок`,
    description: `в форме пятиконечной звезды`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 5,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кривой ножик`,
    description: ``,
  },
  {
    cost: 90,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `стеклянный флакон`,
    description: `с обрезками ногтей`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `прямоугольное металлическое устройство`,
    description: `с двумя крошечными металлическими колпачками на одной стороне. Если его намочить, оно сыпет искры`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `белые перчатки с блёстками`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `одеяние с сотней крохотных карманов`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `маленький каменный кирпич`,
    description: `который ничего не весит`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `крохотный набросок портрета гоблина`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 50,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `пустой флакон`,
    description: `пахнущий духами при открытии`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `драгоценный камень`,
    description: `который всем кроме вас кажется куском угля`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кусок старого знамени`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `знак различия древнего легиона`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 3,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `крохотный серебряный колокольчик`,
    description: `без язычка`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 200,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `механическая канарейка в гномьей лампе`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 50,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `крохотный сундук`,
    description: `вырезанный так, что кажется, что у него невероятно глубокое дно`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `мёртвый спрайт в чистой стеклянной бутылке`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `запаянная металлическая банка`,
    description: `в которой, судя по звукам, находится жидкость`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `запаянная металлическая банка`,
    description: `в которой, судя по звукам, находится песок`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `запаянная металлическая банка`,
    description: `в которой, судя по звукам, находятся пауки`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `запаянная металлическая банка`,
    description: `в которой, судя по звукам, находится битое стекло`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 2000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `стеклянная сфера с водой`,
    description: `в которой плавает механическая золотая рыбка`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `серебряная ложка`,
    description: `с выгравированной на ручке буквой «М»`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 50,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `свисток из дерева`,
    description: `золотого цвета`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `мёртвый скарабей`,
    description: `размером с ладонь`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `два игрушечных солдатика`,
    description: `один — без головы`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `небольшая коробка с пуговицами разного размера`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `свеча`,
    description: `которая никак не загорается`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `крохотная клетка`,
    description: `без дверцы`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `старый ключ`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `не поддающаяся расшифровке карта сокровищ`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `рукоятка от сломанного меча`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кроличья лапка`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 101,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `стеклянный глаз`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 20,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `камея с резным портретом ужасного лица`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 20,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `серебряный череп размером с монету`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `алебастровая маска`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `пирамидка клейкого чёрного дурно пахнущего благовония`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `ночной колпак, дарующий приятные сны`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `один костяной калтроп`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `золотая оправа монокля без линзы`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кубик с длиной ребра 2 сантиметра`,
    description: `все стороны раскрашены в разные цвета`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `хрустальная дверная ручка`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `пакетик розовой пыли`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `два листа пергамента с фрагментом нот прекрасной песни`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `серебряная серьга в виде слезинки`,
    description: `сделанная из настоящей слезинки`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `яичная скорлупа`,
    description: `разрисованная с жуткими подробностями сценами человеческих мук`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `веер`,
    description: `на котором в раскрытом состоянии видно спящую кошку`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `набор костяных трубочек`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 5,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `четырёхлистный клевер`,
    description: `засушенный в книге о манерах и этикете`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `лист пергамента`,
    description: `на котором изображена сложная механическая конструкция`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `разукрашенные ножны`,
    description: `под которые Вы никак не можете найти подходящий клинок`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `приглашение на вечеринку`,
    description: `на которой произошло убийство`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `бронзовая пентаграмма`,
    description: `с выгравированной в центре крысиной головой`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `фиолетовый носовой платок`,
    description: `с вышитым именем великого архимага`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `половинка плана храма`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `половинка плана замка`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `половинка плана какого-то строения`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кусочек сложенной ткани`,
    description: `который, если развернуть, превращается в модную кепку`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `квитанция о депозите`,
    description: `в банке далёкого города`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `дневник`,
    description: `в котором не хватает семь страниц`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `пустая серебряная табакерка`,
    description: `с надписью на поверхности «грёзы»`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 500,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `железный святой символ`,
    description: `неизвестного божества`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 4,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `книга о восхождении и свержении легендарного героя`,
    description: `в которой нет последней главы`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `сосуд с драконьей кровью`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `древняя эльфийская стрела`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `иголка`,
    description: `которая никогда не гнётся`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `красивая дварфийская брошь`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 200,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `пустая бутылка из-под вина`,
    description: `с небольшой наклейкой`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 2000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `«Винокурня Винного Волшебника, Красное драконье, 331422-В»`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `плитка мозаики`,
    description: `с разноцветной глазированной поверхностью`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `окаменевшая мышь`,
    description: ``,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `чёрный пиратский флаг`,
    description: `с черепом и костями дракона`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `крохотный металлический краб`,
    description: `который двигается, когда на него не смотрят`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `крохотный металлический паук`,
    description: `который двигается, когда на него не смотрят`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `стеклянная бутылка сала`,
    description: `с этикеткой «Жир грифона»`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `деревянная коробка с керамическим дном`,
    description: `в которой живёт червяк с двумя головами на каждом конце тела`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `металлическая урна`,
    description: `с прахом героя`,
    source: {
      id: SOURCE_PHB,
      page: 161,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `глазчатый агат`,
    description: `полупрозрачный, чередующиеся круги серого и зелёного цвета`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `глазчатый агат`,
    description: `полупрозрачный, чередующиеся круги серого и белого цвета`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `глазчатый агат`,
    description: `полупрозрачный, чередующиеся круги серого и коричневого цвета`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `глазчатый агат`,
    description: `полупрозрачный, чередующиеся круги серого и голубого цвета`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `глазчатый агат`,
    description: `полупрозрачный, чередующиеся круги белого и коричневого цвета`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `глазчатый агат`,
    description: `полупрозрачный, чередующиеся круги белого и голубого цвета`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `глазчатый агат`,
    description: `полупрозрачный, чередующиеся круги белого и зелёного цвета`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `глазчатый агат`,
    description: `полупрозрачный, чередующиеся круги коричневого и голубого цвета`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `глазчатый агат`,
    description: `полупрозрачный, чередующиеся круги коричневого и зелёного цвета`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `глазчатый агат`,
    description: `полупрозрачный, чередующиеся круги голубого и зелёного цвета`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `голубой кварц`,
    description: `прозрачный, голубой`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `лазурит`,
    description: `непрозрачный, голубой и синий с жёлтыми вкраплениями`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `малахит`,
    description: `непрозрачный, с чередующимися светло- и тёмно-зелёными полосами`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `моховой агат`,
    description: `полупрозрачный, розовый или бело- жёлтый с мшистыми серыми пятнами`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `моховой агат`,
    description: `полупрозрачный, розовый или бело- жёлтый с мшистыми зелёными пятнами`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `обсидиан`,
    description: `непрозрачный, чёрный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `полосчатый агат`,
    description: `полупрозрачный, с чередующимися коричневыми и красными полосами`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `полосчатый агат`,
    description: `полупрозрачный, с чередующимися коричневыми и белыми полосами`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `полосчатый агат`,
    description: `полупрозрачный, с чередующимися коричневыми и голубыми полосами`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `полосчатый агат`,
    description: `полупрозрачный, с чередующимися голубыми и белыми полосами`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `полосчатый агат`,
    description: `полупрозрачный, с чередующимися голубыми и красными полосами`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `полосчатый агат`,
    description: `полупрозрачный, с чередующимися белыми и красными полосами`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `родохрозит`,
    description: `непрозрачный, светло-розовый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `тигровый глаз`,
    description: `полупрозрачный, коричневый с золотой серединкой`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `гелиотроп`,
    description: `непрозрачный, тёмно-серый с красными вкраплениями`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `звёздчатый розовый кварц`,
    description: `полупрозрачный, розовый камень с белой звездой по центру`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кварц`,
    description: `прозрачный, белый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кварц`,
    description: `прозрачный, дымчатый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кварц`,
    description: `прозрачный, жёлтый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `лунный камень`,
    description: `полупрозрачный, белый с бледно-голубым отливом`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `оникс`,
    description: `непрозрачный, чёрно-белые полосы`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `оникс`,
    description: `непрозрачный, чисто чёрный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `оникс`,
    description: `непрозрачный, чисто белый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `сардоникс`,
    description: `непрозрачный, бело-красные полосы`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `сердолик`,
    description: `непрозрачный, оранжевый с переходом в красно-коричневый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `халцедон`,
    description: `непрозрачный, белый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `хризопраз`,
    description: `полупрозрачный, зелёный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `циркон`,
    description: `прозрачный, бледный зелёно-голубой`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `цитрин`,
    description: `прозрачный, жёлто-коричневый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `яшма`,
    description: `непрозрачная, синяя`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `яшма`,
    description: `непрозрачная, чёрная`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `яшма`,
    description: `непрозрачная, коричневая`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `аметист`,
    description: `прозрачный, тёмно-фиолетовый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `гагат`,
    description: `непрозрачный, чёрный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `гранат`,
    description: `прозрачный, красный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `гранат`,
    description: `прозрачный, зелёно-коричневый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `гранат`,
    description: `прозрачный, фиолетовый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `жемчуг`,
    description: `непрозрачный, переливчатый белый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `жемчуг`,
    description: `непрозрачный, переливчатый жёлтый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `жемчуг`,
    description: `непрозрачный, переливчатый розовый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `коралл`,
    description: `непрозрачный, тёмно-красный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `нефрит`,
    description: `полупрозрачный, светло-зелёный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `нефрит`,
    description: `полупрозрачный, тёмно-зелёный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `нефрит`,
    description: `полупрозрачный, белый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `турмалин`,
    description: `прозрачный, красный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `турмалин`,
    description: `прозрачный, бледно-зелёный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `турмалин`,
    description: `прозрачный, синий`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `турмалин`,
    description: `прозрачный, коричневый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `хризоберилл`,
    description: `прозрачный, жёлто-зелёный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `хризоберилл`,
    description: `прозрачный, светло-зелёный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `шпинель`,
    description: `прозрачная, красная`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `шпинель`,
    description: `прозрачная, красно-коричневая`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `шпинель`,
    description: `прозрачная, тёмно-зелёная`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `янтарь`,
    description: `прозрачный, жёлто-золотой`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 50000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `аквамарин`,
    description: `прозрачный, зелёно-голубой`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 50000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `александрит`,
    description: `прозрачный, тёмно-зелёный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 50000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `синяя шпинель`,
    description: `прозрачная, синяя`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 50000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `топаз`,
    description: `прозрачный, золотисто-жёлтый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 50000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `хризолит`,
    description: `прозрачный, сочный оливково-зелёный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 50000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `чёрный жемчуг`,
    description: `непрозрачный, чисто чёрный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 100000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `голубой сапфир`,
    description: `прозрачный, бело-голубой`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 100000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `голубой сапфир`,
    description: `прозрачный, голубой`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 100000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `голубой сапфир`,
    description: `прозрачный, умеренно синий`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 100000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `жёлтый сапфир`,
    description: `прозрачный, огненно-жёлтый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 100000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `жёлтый сапфир`,
    description: `прозрачный, жёлто-зелёный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 100000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `звёздчатый рубин`,
    description: `полупрозрачный, рубин с белёсой звездой в центре`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 100000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `звёздчатый сапфир`,
    description: `полупрозрачный, синий сапфир с белёсой звездой в центре`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 100000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `изумруд`,
    description: `прозрачный, насыщенный ярко-зелёный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 100000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `огненный опал`,
    description: `полупрозрачный, огненно-красный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 100000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `опал`,
    description: `полупрозрачный, голубой с зелёными и золотыми вкраплениями`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 100000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `чёрный опал`,
    description: `полупрозрачный, тёмно-зелёный с чёрными пятнышками и золотыми вкраплениями`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 500000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `алмаз`,
    description: `прозрачный`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 500000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `алмаз`,
    description: `сине-белый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 500000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `алмаз`,
    description: `ярко-жёлтый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 500000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `алмаз`,
    description: `розовый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 500000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `алмаз`,
    description: `коричневый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 500000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `алмаз`,
    description: `синий`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 500000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `гиацинт`,
    description: `прозрачный, огненно-оранжевый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 500000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `рубин`,
    description: `прозрачный, чисто красный с переходом в тёмно-алый`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 500000,
    type: LOOT_TYPE_GEM,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `чёрный сапфир`,
    description: `полупрозрачный, блестящий чёрный с яркими вкраплениями`,
    source: {
      id: SOURCE_DMG,
      page: 135,
    },
  },
  {
    cost: 25000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `золотое кольцо`,
    description: `с гелиотропами`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 25000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `резная статуэтка`,
    description: `из слоновой кости`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 25000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `большой золотой браслет`,
    description: ``,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 25000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `серебряное ожерелье`,
    description: `с кулоном из поделочного камня`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 25000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `бронзовая корона`,
    description: ``,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 25000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `шёлковая мантия`,
    description: `с золотой вышивкой`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 25000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: true,
    name: `большой мастерски вытканный гобелен`,
    description: ``,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 25000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `латунная кружка`,
    description: `инкрустированная нефритом`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 25000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `коробочка`,
    description: `с бирюзовыми фигурками животных`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 25000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: true,
    name: `золотая клетка для птиц`,
    description: `инкрустированная электрумом`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 75000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `серебряная чаша`,
    description: `декорированная лунным камнем`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 75000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `длинный меч`,
    description: `из посеребрённой стали`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 75000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: true,
    name: `резная арфа`,
    description: `из экзотической древесины, с инкрустацией из слоновой кости и драгоценными цирконами`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 75000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `небольшой золотой идол`,
    description: ``,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 75000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `гребень`,
    description: `в виде золотого дракона со вставленными в глаза гранатами`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 75000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `бутыль`,
    description: `запечатанная пробкой с тиснением по золотой фольге и украшенная аметистами`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 75000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `церемониальный кинжал`,
    description: `из электрума с чёрной жемчужиной в навершии`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 75000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `серебряно-золотая брошь`,
    description: ``,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 75000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `обсидиановая статуэтка`,
    description: `с золотой инкрустацией`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 75000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `расписная боевая маска`,
    description: `из золота`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 250000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `прекрасная золотая цепь`,
    description: `с огненным опалом`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 250000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: true,
    name: `старинный шедевр живописи`,
    description: ``,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 250000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `расшитая шёлком и бархатом мантия`,
    description: `украшенная лунными камнями`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 250000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `платиновый браслет`,
    description: `с сапфиром`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 250000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `вышитые перчатки`,
    description: `усыпанные драгоценными камнями`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 250000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `ножной браслет`,
    description: `украшенный драгоценными камнями`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 250000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `золотая музыкальная шкатулка`,
    description: ``,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 250000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `золотой браслет`,
    description: `украшенный аквамаринами`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 250000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `глазная повязка`,
    description: `с ложным глазом из голубого сапфира и лунного камня`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 250000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `ожерелье`,
    description: `из маленьких розовых жемчужин`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 750000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: true,
    name: `золотая корона`,
    description: `украшенная драгоценностями`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 750000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `платиновое кольцо`,
    description: `украшенное драгоценностями`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 750000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `небольшая золотая статуэтка`,
    description: `с рубинами`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 750000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `золотой кубок`,
    description: `с изумрудами`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 750000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `золотая шкатулка для драгоценностей`,
    description: `с платиновой филигранью`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 750000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: true,
    name: `детский саркофаг`,
    description: `расписанный золотом`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 750000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `нефритовая игральная доска`,
    description: `с золотыми фигурками`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 750000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `украшенный драгоценными камнями рог для напитков`,
    description: `из слоновой кости, инкрустированный золотой филигранью`,
    source: {
      id: SOURCE_DMG,
      page: 136,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `поношенный мешочек`,
    description: `с костями животных`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 2,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `практически пустая кожаная фляга`,
    description: `с грязной водой`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `гниющий кусок мяса`,
    description: `завёрнутый в грязную ткань`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 180,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `ржавые кандалы`,
    description: ``,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 5,
    type: LOOT_TYPE_FOOD,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `грязная стеклянная бутылка`,
    description: `с крепким алкоголем`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 4,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `пустая маленькая деревянная клетка`,
    description: ``,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 5,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `грубая деревянная клетка`,
    description: `с живой мышью внутри`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `маленькая стеклянная банка`,
    description: `с несколькими святлячками внутри`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `грубый тканевый мешочек`,
    description: `с листьями и корешками какого-то растения`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 180,
    type: LOOT_TYPE_WEAPON,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кинжал`,
    description: `покрытый запёкшейся кровью`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 180,
    type: LOOT_TYPE_WEAPON,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кинжал`,
    description: `покрытый какой-то непонятной бурой жидкостью`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 180,
    type: LOOT_TYPE_WEAPON,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `грязный кинжал`,
    description: ``,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 40,
    type: LOOT_TYPE_WEAPON,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `8 стрел`,
    description: `перевязанных грубой верёвочкой`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 50,
    type: LOOT_TYPE_WEAPON,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `10 стрел`,
    description: `перевязанных грубой верёвочкой`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 60,
    type: LOOT_TYPE_WEAPON,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `12 стрел`,
    description: `перевязанных грубой верёвочкой`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `сломанная стрела`,
    description: `покрытая кровью`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `ожерелье из зубов`,
    description: `с продетой через них нитью`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `ожерелье из косточек`,
    description: `с продетой через них леской`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 5,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `ржавое ожерелье`,
    description: `из цепи с грубым медальоном из свинца`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `маленький мешочек из ткани`,
    description: `с коллекцией ювелирных украшений внутри`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `сильно размокшая книга`,
    description: `с нечитаемым текстом`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `несколько отрезанных пальцев`,
    description: `обёрнутых в ткань. Пальцы похожи на эльфийские`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `парочка отрезанных пальцев`,
    description: `обёрнутых в кожу. Пальцы похожи на человеческие`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 30,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `небольшая баночка`,
    description: `наполненная маслом и с небольшим черепом животного внутри`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 5,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `череп гнома`,
    description: `Местами заляпан чем-то бурым`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 5,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: true,
    name: `череп человека`,
    description: `Сильно повреждён, местами нет больших кусков`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `череп дварфа`,
    description: `Огромная дыра в лобовой части`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `череп драконорождённего`,
    description: `Обезображен какими-то костными наростами`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `крохотная деревянная коробочка`,
    description: `Она пустая, но внутри вырезан символ большого глаза`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `крохотная деревянная коробочка`,
    description: `Она пустая, но внутри вырезан символ меча`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 200,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `маленькая железная коробочка`,
    description: `Внутри лежит небольшой серебрянный кулон`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 20,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `маленькая железная коробочка`,
    description: `Внутри пусто, но виднеется грубо нацарапанный символ в форме луны`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 40,
    type: LOOT_TYPE_CLOTH,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `старый мешок`,
    description: `полный грязной одежды`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `пустой пыльный мешок`,
    description: ``,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 3,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `мешок`,
    description: `внутри которого ещё пара мешков`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 15,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `грубая коробочка из дерева`,
    description: `с набором игральных костей и фигуркой, вырезанной из камня`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 40,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `свёрнутая верёвка`,
    description: `длиной 20 футов, на конце завязан узел`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 30,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `свёрнутая верёвка`,
    description: `длиной 30 футов, сильно потёртая`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 40,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `свёрнутая верёвка`,
    description: `длиной 40 футов, вся пропитана чем-то жёлтым`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `примитивный набор из бронзовых колышков, шил и палочек`,
    description: `Некоторые из них грязные, надломанные и грязные. При некоторой чистке и починке может сойти за набор воровских инструментов`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 35,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `ржавая масляная лампа`,
    description: `судя по всему, дварфийской работы. Она течёт, но простой ремонт позволит ей пользоваться`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `сильно изношенный ломик`,
    description: `частично гнутый`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 400,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `треснувшее серебрянное ручное зеркальце`,
    description: ``,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 300,
    type: LOOT_TYPE_FOOD,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `2 фунта сушёного мяса`,
    description: `завёрнутого в пергамент`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 50,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: true,
    name: `маленький, грубый сундучок`,
    description: `со сломанной петлёй. Он набит несвежими яблоками`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `маленький, грубый сундучок`,
    description: `выглядящий обожжёным. Он полон черепов животных`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `маленький, грубый сундучок`,
    description: `с кривым символом в форме ворона на крышке. Внутри — несколько пустых стеклянных бутылочек`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 50,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `спальный мешок`,
    description: `Посередине большое коричневое пятно, но в остальном он в хорошем состоянии`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 50,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `спальный мешок`,
    description: `Местами есть разрывы, но его можно починить`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 30000,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `закрытый кожаный мешочек`,
    description: `с тремя бутылками яда`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 3,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `набор из грязных кастрюль`,
    description: `связанных вместе верёвкой`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 2,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `сковородка`,
    description: `сильно попользованная`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `записка на Орочьем`,
    description: `с каким-то кривыми рисунками и крестиками`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `нечестивый амулет`,
    description: `сделанный из нескольких костей животных`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 2000,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `сильно повреждённая книга волшебника`,
    description: `Она нечитаема, но можно разобрать что она принадлежала специалисту в школе Воплощения`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 2000,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `сильно повреждённая книга волшебника`,
    description: `Она нечитаема, но можно разобрать что она принадлежала специалисту в школе Вызова`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 2000,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `сильно повреждённая книга волшебника`,
    description: `Можно разобрать некоторые записи и что она принадлежала специалисту в школе Иллюзии`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `убогий набор для рыбалки`,
    description: `Примитивная удочка из палки и верёвки и набор живых личинок в коробочке`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 15,
    type: LOOT_TYPE_CLOTH,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `набор одежды маленького размера`,
    description: `Местами видно порезы и большое чёрное пятно в области груди`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 20,
    type: LOOT_TYPE_CLOTH,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `набор одежды среднего размера`,
    description: `Рукав начисто чем-то отрезан`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 20,
    type: LOOT_TYPE_CLOTH,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `набор одежды среднего размера`,
    description: `Грязный, но целый`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TOOL,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кривой, но рабочий капкан`,
    description: `Сделан из почерневшего дерева и ржвавого железа`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 5,
    type: LOOT_TYPE_TOOL,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `набор потрёпанных научных инструментов`,
    description: `Треснувший компас, грязное перо, баночка с чернилами и заляпанные линейки. Можно починить и использовать как набор картографа`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `тряпка`,
    description: `очень грязная`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `эфес`,
    description: `от сломанного меча`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `примитивные бинты`,
    description: `видимо сделанные из кусков одежды`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 1,
    type: LOOT_TYPE_WEAPON,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `каменный нож`,
    description: `покрытый жиром`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `обгоревшая соломенная детская кукла`,
    description: ``,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_CLOTH,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `шапочка`,
    description: `плохо сшитая из нескольких шкур крыс`,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 0,
    type: LOOT_TYPE_CLOTH,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `вонючий носок`,
    description: ``,
    source: {
      url: `https://www.reddit.com/r/d100/comments/ayd2il/d50_items_of_loot_to_be_found_from_wildtribal/`,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `стеклянная колба в виде цифры`,
    description: `(брось к10)`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 5,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `тонкая книга`,
    description: `с полностью залитыми кровью страницами`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: true,
    name: `хрустальная стрела`,
    description: ``,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 100,
    type: LOOT_TYPE_ART,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `крохотный гроб с лежащим внутри скелетиком`,
    description: ``,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 2000,
    type: LOOT_TYPE_WEAPON,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: true,
    name: `огромный моргенштерн`,
    description: `расписанный изящным флористическим узором`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `шахматная фигурка`,
    description: `в виде ворона`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 10,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `ключ`,
    description: `для чрезвычайно сложного замка`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 5000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `золотое перо`,
    description: `магически висящее в воздухе`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 2000,
    type: LOOT_TYPE_CLOTH,
    isCleverItem: true,
    isDumbItem: false,
    isOnlyLargeCreatureItem: false,
    name: `красивый плащ`,
    description: `из сложного мелкого кружева`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 700,
    type: LOOT_TYPE_CLOTH,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `кожаный ремень`,
    description: `с бляхой в виде головы льва`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 5,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: false,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `мешочек`,
    description: `с разноцветными камешками`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 5,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `слипшийся свиток`,
    description: `который невозможно развернуть`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 10000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `зеркальце`,
    description: `в котором отражаются только неживые предметы`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 500,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `железное кольцо`,
    description: `с буквами ДРВД`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 1000,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `тонкая цепочка`,
    description: `восьмиметровая, с гирькой`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 50,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `ложка`,
    description: `двусторонняя`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
  {
    cost: 500,
    type: LOOT_TYPE_TRINKET,
    isCleverItem: true,
    isDumbItem: true,
    isOnlyLargeCreatureItem: false,
    name: `маска`,
    description: `реалистичная`,
    source: {
      url: `https://vk.com/wall-183051833_696?w=wall-183051833_696`,
    },
  },
]


